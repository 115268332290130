// Booking stages indicator

.booking-stages {
    --stages--bg-color: white;
    --stages--border-color: #{$brand--forest};
    --stages--color: #{$brand--forest};
    --stages--label-color: #{$brand--forest};
    --stages--bg-color--active: #{$brand--river};
    --stages--border-color--active: #{$brand--river};
    --stages--color--active: white;
    --stages--bg-color--complete: #{$brand--forest};
    --stages--border-color--complete: #{$brand--forest};
    --stages--color--complete: white;
    ol {
        counter-reset: stage-counter;
        display: flex;
        position: relative;
        width: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
        &:before {
            display: block;
            content: "";
            position: absolute;
            z-index: 1;
            top: 14px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: var(--stages--border-color);
        }
        li {
            counter-increment: stage-counter;
            display: block;
            flex: 1 1 auto;
            position: relative;
            z-index: 2;
            margin: 0;
            padding: 0;
            text-align: center;
            .booking-stages--item {
                display: block;
                width: 100%;
                color: $body-color;
                font-family: $alt-headings-font-family;
                font-weight: $alt-headings-font-weight;
                letter-spacing: $alt-headings-letter-spacing;
                text-transform: uppercase;
                font-size: 14px;
                line-height: 14px;
                color: var(--stages--label-color);
                &:before {
                    display: flex;
                    margin: 0 auto 8px;
                    justify-content: center;
                    align-items: center;
                    content: counter(stage-counter);
                    width: 30px;
                    height: 30px;
                    border: 2px solid var(--stages--border-color);
                    border-radius: 50%;
                    font-family: $alt-headings-font-family;
                    font-weight: $alt-headings-font-weight;
                    font-size: 18px;
                    line-height: 18px;
                    background-color: var(--stages--bg-color);
                    color: var(--stages--color);
                }
                &.complete:before {
                    content: '';
                    background: var(--stages--bg-color--complete) url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E") no-repeat;
                    background-size: 50%;
                    background-position: center;
                    border-color: var(--stages--bg-color--complete);
                    color: var(--stages--color--complete);
                }
                &.active:before {
                    background-color: var(--stages--bg-color--active);
                    border-color: var(--stages--bg-color--active);
                    --color: var(--stages--color--active);
                    color: var(--stages--color--active);
                }
            }
            a.booking-stages--item {
                color: var(--stages--label-color);
                text-decoration: none;
                &:hover,
                &:focus {
                    color: var(--stages--label-color);
                    text-decoration: none;
                    &:before {
                        text-decoration: none !important;
                    }
                    .label {
                        text-decoration: underline;
                    }
                }
            }
        }

        @media(max-width: 500px) {
            .booking-stages--item:not(.active) .label {
                display: none;
            }
        }

        @include media-breakpoint-up(md) {
            &:before {
                top: 19px;
            }
            li {
                width: calc(100% / 6);
                .booking-stages--item {
                    font-size: 16px;
                    line-height: 16px;
                    &:before {
                        width: 40px;
                        height: 40px;
                        font-size: 20px;
                        line-height: 20px;
                    }
                    &.complete:before {
                        background-size: 40%;
                    }
                }
            }
        }
    }
}
