h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: var(--theme--color--emphasis);
    margin-bottom: max(1rem, 0.5em);
    letter-spacing: $headings-letter-spacing;
    strong, b {
        font-weight: $headings-font-weight;
    }
}

.alt-heading-font {
    font-family: $alt-headings-font-family;
    font-weight: $alt-headings-font-weight;
    line-height: $alt-headings-line-height;
    letter-spacing: $alt-headings-letter-spacing;
    text-transform: uppercase;
    strong, b {
        font-weight: $alt-headings-font-weight;
    }
}

.color-body {
    color: var(--theme--color) !important;
}

.text-muted {
    color: var(--theme--color--subtle) !important;
}

.no-wrap {
    white-space: nowrap;
}

b, strong, .strong {
    font-weight: $font-weight-bold;
}

.opening-status-label {
    display: inline-block;
    min-width: 5.4em;
}

// placeholder font-awesome size
i.fa-solid, i.fa-light, i.fa-regular, i.fa-sharp {
    display: inline-block;
    min-width: 1em;
    height: 1em;
}

// icon styling shims
.tripadvisor-icon, .tiktok-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
}
