// shortcuts bar

.shortcuts-bar {
    @include media-breakpoint-down(xl) {
        --edge-padding: 0;
    }
    ul {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        gap: 1px;
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            display: block;
            width: 100%;
            margin: 0;
            padding: 0;
            list-style: none;
            min-height: 100%;
            &:not(:first-child) {
                border-left: 1px solid var(--theme--rule--color);
            }
            a {
                display: flex;
                flex-direction: column;
                gap: 1px;
                align-items: center;
                min-height: 100%;
                text-align: center;
                padding: 6px 0 10px;
                background-color: var(--theme--background-color);
                color: var(--theme--color);
                transition: background-color 300ms linear, color 300ms linear;
                .shortcuts--icon {
                    font-size: 1.4rem;
                }
                .shortcuts--heading {
                    display: block;
                    font-family: $alt-headings-font-family;
                    font-weight: $alt-headings-font-weight;
                    line-height: $alt-headings-line-height;
                    text-transform: uppercase;
                    font-size: 1rem;
                    svg {
                        margin-right: -0.45em;
                    }
                }
                .shortcuts--detail {
                    display: block;
                    font-size: 12px;
                    color: var(--theme--color--subtle);
                }
                &:hover, &:focus {
                    background-color: var(--theme--btn--primary--bg-color--hover);
                    color: var(--theme--btn--primary--color--hover);
                    text-decoration: none;
                    transition-duration: 100ms, 100ms;
                    .shortcuts--heading {
                        text-decoration: underline;
                    }
                    .shortcuts--detail {
                        color: var(--theme--btn--primary--color--hover);
                    }
                }
                @include media-breakpoint-up(lg) {
                    .shortcuts--icon {
                        font-size: 1.6rem;
                    }
                    .shortcuts--heading {
                        font-size: $font-size-base * 1.2;
                    }
                    .shortcuts--detail {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

// taglist

ul.taglist {
    display: inline-flex;
    gap: 5px;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        a {
            display: inline-flex;
            align-items: center;
            border: 1px solid var(--theme--color);
            background-color: transparent;
            color: var(--theme--color);
            padding: 2px 8px;
            min-height: 16px;
            border-radius: 3rem;
            font-weight: $font-weight-bold;
            font-size: 10px;
            text-transform: uppercase;
            white-space: nowrap;
            &:hover, :focus {
                border-color: var(--theme--link--color--hover);
                background-color: var(--theme--link--color--hover);
                color: white;
                text-decoration: none;
            }
        }
    }
}

// visual listing

.visual-listing {
    li {
        min-height: 100%;
        margin-bottom: $grid-gutter-width;
    }
    &--item {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        &--image {
            width: 100%;
            flex: 0 0 auto;
            .visual-listing--item--label {
                display: inline-block;
                position: absolute;
                z-index: 2;
                top: 0;
                right: 0;
                font-family: $alt-headings-font-family;
                font-weight: $alt-headings-font-weight;
                line-height: $alt-headings-line-height;
                letter-spacing: $alt-headings-letter-spacing;
                text-transform: uppercase;
                font-size: 1rem;
                background-color: $brand--apple;
                color: white;
                padding: 3px 10px 5px;
            }
        }
        &--content {
            width: 100%;
            flex: 1 0 auto;
            padding: var(--panel--padding); // default padding
            & > *:last-child {
                margin-bottom: 0;
            }
            h3 {
                --theme--link--color: var(--theme--color--emphasis);
                color: var(--theme--color--emphasis);
            }
            .visual-listing--title-with-arrow {
                display: flex;
                justify-content: space-between;
                gap: 5px;
                svg {
                    margin-top: 0.05em;
                }
            }
            .visual-listing--item--content--multi-cta {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                margin: 0;
                justify-content: space-between;
                align-items: flex-start;
                gap: 10px;
            }
        }
    }
    a.visual-listing--item {
        color: var(--theme--color);
        .visual-listing--item--content {
            h3 {
                color: var(--theme--link--color);
            }
        }
        &:hover, &:focus {
            color: var(--theme--color);
            text-decoration: none;
            .visual-listing--item--content {
                h3 {
                    color: var(--theme--link--color--hover);
                    text-decoration: underline;
                }
            }
        }
    }

    .product-listing & {
        .out-of-stock {
            display: inline-block;
            background-color: $brand--apple;
            color: white;
            font-size: 0.875rem;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            padding: 2px 5px;
        }
        .stock-info {
            color: $brand--apple;
        }
        .visual-listing--pricing {
            font-weight: $font-weight-bold;
            font-size: 1.125rem;
            .visual-listing--pricing--prev {
                font-weight: $font-weight-base;
                text-decoration: line-through;
                opacity: 0.8;
                margin-right: 0.5em;
            }
        }
    }
}

.listing--filter-panel {
    select.btn {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
    }
    .filter-options--date-dropdown {
        .dropdown-menu {
            & > li:not(:last-child) {
                border-bottom: 1px solid #ced4da;
            }
            .date-dropdown--option:not(.date-range) {
                label {
                    display: block;
                    position: relative;
                    padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
                    color: var(--bs-dropdown-link-color);
                    font-size: $btn-font-size-sm;
                    font-family: $alt-headings-font-family;
                    font-weight: $alt-headings-font-weight;
                    line-height: $alt-headings-line-height;
                    text-transform: uppercase;
                    cursor: pointer;
                    &:before {
                        display: block;
                        content: '';
                        width: 0.9em;
                        height: 1em;
                        background-color: white;
                        mask-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' role='img' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 512 512'%3E%3Cpath d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z' class=''%3E%3C/path%3E%3C/svg%3E");
                        mask-size: 100% 100%;
                        position: absolute;
                        top: 0.6rem;
                        left: 0.3em;
                        opacity: 0;
                        transform: scale(0.5) rotate(-3deg);
                        transition: transform 200ms ease-in-out, opacity 200ms linear;
                        will-change: transform;
                    }
                    span {
                        display: inline-block;
                        will-change: transform;
                        transition: transform 200ms ease-in-out;
                    }
                }
                input[type="radio"]:checked + label {
                    background-color: var(--theme--color);
                    border-color: var(--theme--color);
                    color: var(--theme--color--emphasis--invert);
                    &:before {
                        opacity: 1;
                        transform: scale(1) rotate(-3deg);
                    }
                    span {
                        transform: translate3d(0.75em, 0, 0);
                    }
                }
            }
            .date-dropdown--option.date-range {
                padding: $input-btn-padding-y-sm $input-btn-padding-x-sm $input-btn-padding-x-sm;
                label {
                    font-family: $alt-headings-font-family;
                    font-weight: $alt-headings-font-weight;
                    line-height: $alt-headings-line-height;
                    text-transform: uppercase;
                }
                input[type="text"] {
                    background-color: white;
                    @include media-breakpoint-down(sm) {
                        padding-left: 5px;
                        padding-right: 5px;
                    }
                }
                &.active {
                    background-color: var(--theme--color);
                    .form-label.text-muted {
                        color: var(--theme--color--emphasis--invert) !important;
                    }
                }
                .date-dropdown--calendars {
                    width: 100%;
                    position: relative;
                    .pika-single {
                        width: 100%;
                        .pika-lendar {
                            width: calc(100% - 16px);
                        }
                    }
                }
            }
        }
    }

    ul.filter-tags-list {
        display: inline-flex;
        gap: 10px;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
        li.filter-tag {
            display: block;
            list-style: none;
            margin: 0;
            padding: 0;
            input[type="checkbox"] + label {
                position: relative;
                &:before {
                    display: block;
                    content: '';
                    width: 0.9em;
                    height: 1em;
                    background-color: white;
                    mask-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' role='img' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 512 512'%3E%3Cpath d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z' class=''%3E%3C/path%3E%3C/svg%3E");
                    mask-size: 100% 100%;
                    position: absolute;
                    top: 0.6rem;
                    left: 0.3em;
                    opacity: 0;
                    transform: scale(0.5) rotate(-3deg);
                    transition: transform 200ms ease-in-out, opacity 200ms linear;
                    will-change: transform;
                }
                span {
                    display: inline-block;
                    will-change: transform;
                    transition: transform 200ms ease-in-out;
                }
            }
            input[type="checkbox"]:checked + label {
                background-color: var(--theme--color);
                border-color: var(--theme--color);
                color: var(--theme--color--emphasis--invert);
                &:before {
                    opacity: 1;
                    transform: scale(1) rotate(-3deg);
                }
                span {
                    transform: translate3d(0.5em, 0, 0);
                }
            }
        }
    }
}

// wide-image-text-layout--listing

.wide-image-text-layout--listing {
    border-top: 1px solid var(--theme--rule--color);
    & > li {
        padding: var(--panel--padding) 0;
        border-bottom: 1px solid var(--theme--rule--color);
    }
    &.hide-top-bottom-rules {
        border-top: 0;
        & > li:first-child {
            padding-top: 0;
        }
        & > li:last-child {
            padding-bottom: 0;
            border-bottom: 0;
        }
    }
}
