/* volkhov-700 - latin */
@font-face {
    font-family: 'Volkhov';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/volkhov-v17-latin/volkhov-v17-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../fonts/volkhov-v17-latin/volkhov-v17-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/volkhov-v17-latin/volkhov-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/volkhov-v17-latin/volkhov-v17-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/volkhov-v17-latin/volkhov-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../fonts/volkhov-v17-latin/volkhov-v17-latin-700.svg#Volkhov') format('svg'); /* Legacy iOS */
}
/* montserrat-400 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/montserrat-v25-latin/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../fonts/montserrat-v25-latin/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/montserrat-v25-latin/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/montserrat-v25-latin/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/montserrat-v25-latin/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../fonts/montserrat-v25-latin/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
src: url('../../fonts/montserrat-v25-latin/montserrat-v25-latin-700.eot'); /* IE9 Compat Modes */
src: local(''),
        url('../../fonts/montserrat-v25-latin/montserrat-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../../fonts/montserrat-v25-latin/montserrat-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('../../fonts/montserrat-v25-latin/montserrat-v25-latin-700.woff') format('woff'), /* Modern Browsers */
        url('../../fonts/montserrat-v25-latin/montserrat-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../../fonts/montserrat-v25-latin/montserrat-v25-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* barlow-condensed-600 - latin */
@font-face {
font-family: 'Barlow Condensed';
font-style: normal;
font-weight: 600;
src: url('../../fonts/barlow-condensed-v12-latin/barlow-condensed-v12-latin-600.eot'); /* IE9 Compat Modes */
src: local(''),
        url('../../fonts/barlow-condensed-v12-latin/barlow-condensed-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../../fonts/barlow-condensed-v12-latin/barlow-condensed-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
        url('../../fonts/barlow-condensed-v12-latin/barlow-condensed-v12-latin-600.woff') format('woff'), /* Modern Browsers */
        url('../../fonts/barlow-condensed-v12-latin/barlow-condensed-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../../fonts/barlow-condensed-v12-latin/barlow-condensed-v12-latin-600.svg#BarlowCondensed') format('svg'); /* Legacy iOS */
}
