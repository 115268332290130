
#header-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 10;

    header {
        height: var(--header--height);
    }

    #skip-nav {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: var(--header--height);
        background: $brand--river;
        font-size: 1rem;
        line-height: 1rem;
        text-decoration: underline;
        opacity: 0;
        pointer-events: none;
        transition: opacity 200ms linear;
        &:focus {
            opacity: 1;
            pointer-events: all;
        }
    }

    .header--content {
        --theme--link--color: var(--theme--color);
        --logo-width: 90px;
        display: flex;
        gap: 20px;
        width: 100%;
        height: var(--header--height);
        justify-content: center;
        align-items: center;

        .header--left-content, .header--right-content {
            display: flex;
            gap: 10px 20px;
            flex-wrap: wrap;
            width: calc((100% - (var(--logo-width) + 40px)) /2);
            align-items: center;
            justify-content: flex-start;
            font-family: $alt-headings-font-family;
            font-weight: $alt-headings-font-weight;
            line-height: $alt-headings-line-height;
            letter-spacing: $alt-headings-letter-spacing;
            text-transform: uppercase;
            & > a {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                @include media-breakpoint-down(sm) {
                    max-width: 60px;
                    svg.fa-arrow-left {
                        margin-right: 0;
                    }
                }
                span.label {
                    font-size: 12px;
                }
            }
        }
        .header--right-content {
            justify-content: flex-end;
        }
        @include media-breakpoint-up(md) {
            .header--left-content, .header--right-content {
                gap: 30px;
                a span.label {
                    font-size: 16px;
                }
            }
            .header--left-content {
                justify-content: flex-start;
            }
            .header--right-content {
                justify-content: flex-end;
            }
        }

        .dropdown.header--main-site-link {
            .dropdown-toggle {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                @include media-breakpoint-down(sm) {
                    max-width: 60px;
                    svg.fa-arrow-left {
                        margin-right: 0;
                    }
                    span.label {
                        white-space: wrap;
                    }
                }
                &:after {
                    display: none;
                    content: none;
                }
                span.label {
                    font-size: 12px;
                    @include media-breakpoint-up(md) {
                        font-size: 16px;
                    }
                }
            }
            .dropdown-menu {
                border: 0;
                filter: drop-shadow(0 3px 10px rgba(black, .1));
                will-change: transform;
                a {
                    text-align: left;
                    align-items: start;
                }
            }
        }

        .header--logo {
            display: block;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: var(--logo-width);
            a img {
                position: relative;
                z-index: 2;
                width: 100%;
                height: auto;
            }
        }

        .header--basket-link {
            position: relative;
            #header--basket-link--indicator {
                position: absolute;
                top: -12px;
                right: -8px;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 18px;
                min-height: 18px;
                padding: 1px;
                border-radius: 50%;
                font-size: 13px !important;
                line-height: 1;
                background-color: $brand--apple;
                color: white;
                .header--basket-link--indicator--number {
                    transform: translateY(0.02em);
                }
            }
        }

        @include media-breakpoint-up(lg) {
            --logo-width: 110px;
        }

        @include media-breakpoint-up(xxl) {
            --logo-width: 125px;
        }
    }

}
