#main-footer {
    margin-top: 80px;
    position: relative;
    padding: 60px var(--edge-padding) 30px;
    footer {

        #main-footer--primary-links {
            ul {
                display: flex;
                width: 100%;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                li {
                    margin: 0;
                    text-align: center;
                }
                @include media-breakpoint-up(md) {
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 15px 30px;
                    li {
                        text-align: left;
                    }
                }
            }
        }
                
        #main-footer--social-links {
            --theme--link--color: var(--theme--color);
            ul {
                display: flex;
                width: 100%;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 15px 20px;
                justify-content: center;
                font-size: 1.6rem;
                @include media-breakpoint-up(md) {
                    gap: 15px 30px;
                }
                li {
                    margin: 0;
                }
            }
        }
        
        #main-footer--legal-copy {
            p {
                text-align: center;
                margin-bottom: 5px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .footer--monogram {
            display: block;
            max-width: 50px;
            height: auto;
            margin: 0;
        }

    }
}
