@mixin non-retina-font-smoothing-antialiased {
    @media(-webkit-min-device-pixel-ratio: 1) {
        -webkit-font-smoothing: antialiased;
    }
}

// theme mixins

@mixin on-light-theme-vars {
    // basic
    --theme--color: #{$dark};
    color: var(--theme--color);
    // for headings, quotes etc
    --theme--color--emphasis: #{$brand--bark};
    --theme--color--emphasis--invert: white;
    // for minor text, helper text etc
    --theme--color--subtle: #{$text-muted};
    // links
    --theme--link--color: #{$link-color};
    --theme--link--text-decoration: #{$link-decoration};
    --theme--link--color--hover: #{$link-hover-color};
    --theme--link--text-decoration--hover: #{$link-hover-decoration};
    // icons, bullets, rules etc
    --theme--decor--color: #{$brand--forest};
    --theme--rule--color: #{$color--lighter-grey};
    // button - standard
    --theme--btn--bg-color: #{$brand--bark};
    --theme--btn--border-color: #{$brand--bark};
    --theme--btn--color: white;
    --theme--btn--bg-color--hover: #{$brand--leaf};
    --theme--btn--border-color--hover: #{$brand--leaf};
    --theme--btn--color--hover: white;
    // button - primary
    --theme--btn--primary--bg-color: #{$brand--apple};
    --theme--btn--primary--border-color: #{$brand--apple};
    --theme--btn--primary--color: white;
    --theme--btn--primary--bg-color--hover: #{$brand--leaf};
    --theme--btn--primary--border-color--hover: #{$brand--leaf};
    --theme--btn--primary--color--hover: white;
    // button - secondary
    --theme--btn--secondary--bg-color: #{$brand--bark};
    --theme--btn--secondary--border-color: #{$brand--bark};
    --theme--btn--secondary--color: white;
    --theme--btn--secondary--bg-color--hover: #{$brand--leaf};
    --theme--btn--secondary--border-color--hover: #{$brand--leaf};
    --theme--btn--secondary--color--hover: white;
    // forms
    --theme--sub-form--bg-color: #{$color--lightest-grey};
}

@mixin on-dark-theme-vars {
    // basic
    --theme--color: #{$brand--sand--light};
    color: var(--theme--color);
    // for headings, quotes etc
    --theme--color--emphasis: white;
    --theme--color--emphasis--invert: #{$brand--river};
    // for minor text, helper text etc
    --theme--color--subtle: #{$brand--leaf};
    // links
    --theme--link--color: white;
    --theme--link--text-decoration: #{$link-decoration};
    --theme--link--color--hover: white;
    --theme--link--text-decoration--hover: #{$link-hover-decoration};
    // icons, bullets, rules etc
    --theme--decor--color: #{$brand--leaf};
    --theme--rule--color: #{rgba(white, 0.4)};
    // button - standard
    --theme--btn--bg-color: #{lighten($brand--bark,10%)};
    --theme--btn--border-color: #{lighten($brand--bark,10%)};
    --theme--btn--color: white;
    --theme--btn--bg-color--hover: #{$brand--leaf};
    --theme--btn--border-color--hover: #{$brand--leaf};
    --theme--btn--color--hover: white;
    // button - primary
    --theme--btn--primary--bg-color: #{$brand--apple};
    --theme--btn--primary--border-color: #{$brand--apple};
    --theme--btn--primary--color: white;
    --theme--btn--primary--bg-color--hover: #{$brand--leaf};
    --theme--btn--primary--border-color--hover: #{$brand--leaf};
    --theme--btn--primary--color--hover: white;
    // button - secondary
    --theme--btn--secondary--bg-color: #{lighten($brand--bark,10%)};
    --theme--btn--secondary--border-color: #{lighten($brand--bark,10%)};
    --theme--btn--secondary--color: white;
    --theme--btn--secondary--bg-color--hover: #{$brand--leaf};
    --theme--btn--secondary--border-color--hover: #{$brand--leaf};
    --theme--btn--secondary--color--hover: white;
    // forms
    --theme--sub-form--bg-color: #{$brand--bark};
}
