.carousel {
    --carousel-indicators-radius: 5px;
    .carousel-indicators {
        bottom: 20px;
        margin: 0;
        padding: 0;
        gap: max(6px, var(--carousel-indicators-radius));
        li[data-bs-target] {
            width: calc(var(--carousel-indicators-radius) * 2);
            height: calc(var(--carousel-indicators-radius) * 2);
            background-color: white;
            border: 0;
            border-radius: var(--carousel-indicators-radius);
            margin: 0;
            padding: 0;
            transition: all 500ms ease-in-out;
        }
        @include media-breakpoint-up(lg) {
            .hero & {
                bottom: 110px;
                .fullscreen-top-section & {
                    bottom: 20px;
                }
            }
        }
    }

    .carousel-control-prev, .carousel-control-next {
        --offset: 0px;
        display: block;
        width: 30px;
        height: 50px;
        position: absolute;
        top: 50%;
        width: calc(30px + var(--offset));
        height: 50px;
        transform: translateY(-50%);
        overflow: hidden;
        pointer-events: none;
        opacity: 1;
        @include media-breakpoint-up(lg) {
            --offset: 10px;
        }
        button {
            pointer-events: all;
            appearance: none;
            border: 0;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(30px + var(--offset));
            height: 50px;
            background-color: var(--theme--btn--primary--bg-color);
            color: var(--theme--btn--primary--color);
            cursor: pointer;
            transition: all 250ms ease-in-out;
            will-change: transform;
            svg {
                margin: 0 !important;
            }
            &:not(:disabled):not(.disabled) {
                &:hover, &:focus {
                    background-color: var(--theme--btn--primary--bg-color--hover);
                    color: var(--theme--btn--primary--color--hover);
                    transform: translateX(0);
                }
            }
        }
    }
    .carousel-control-prev {
        left: 0;
        button {
            border-radius: 0 25px 25px 0;
            transform: translateX(calc(0px - var(--offset)));
            padding-left: var(--offset);
        }
    }
    .carousel-control-next {
        right: 0;
        button {
            border-radius: 25px 0 0 25px;
            transform: translateX(var(--offset));
            padding-right: var(--offset);
        }
    }

}

.swiper-prev-btn, .swiper-next-btn {
    appearance: none;
    border: 0;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
    border-radius: 50%;
    background-color: var(--theme--btn--primary--bg-color);
    color: var(--theme--btn--primary--color);
    cursor: pointer;
    transition: all 250ms ease-in-out;
    will-change: transform;
    svg {
        margin: 0 !important;
    }
    &:not(:disabled):not(.disabled) {
        &:hover, &:focus {
            background-color: var(--theme--btn--primary--bg-color--hover);
            color: var(--theme--btn--primary--color--hover);
        }
    }
}

.swiper-pagination {
    --carousel-indicators-radius: 5px;
    .swiper-pagination-bullet {
        width: calc(var(--carousel-indicators-radius) * 2);
        height: calc(var(--carousel-indicators-radius) * 2);
        background-color: var(--theme--btn--primary--bg-color);
        opacity: .5;
        border: 0;
        border-radius: var(--carousel-indicators-radius);
        margin: 0;
        padding: 0;
        transition: all 500ms ease-in-out;
        margin: 0 !important;
        &:not(:first-child) {
            margin-left: max(6px, var(--carousel-indicators-radius)) !important;
        }
        &:not(.swiper-pagination-bullet-active) {
            &:hover, &:focus {
                background-color: var(--theme--btn--primary--bg-color--hover);
                opacity: 1;
            }
        }
        &.swiper-pagination-bullet-active {
            background-color: var(--theme--color);
            opacity: 1;
        }
    }
}
