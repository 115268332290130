// light themes

.theme--white, .theme--sand, .theme--stone, .theme--lightest-grey {
    @include on-light-theme-vars;
}
.theme--white {
    --theme--background-color: white;
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
}
.theme--sand {
    --theme--background-color: #{$brand--sand};
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
    --theme--rule--color: #{darken($brand--sand,15%)};
    --theme--sub-form--bg-color: #{darken($brand--sand,5%)};
}
.theme--stone {
    --theme--background-color: #{$color--stone};
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
    --theme--rule--color: #{darken($color--stone,15%)};
    --theme--sub-form--bg-color: #{darken($color--stone,5%)};
}
.theme--lightest-grey {
    --theme--background-color: #{$color--lightest-grey};
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
    --theme--rule--color: #{$brand--sand--light};
    --theme--sub-form--bg-color: #{darken($color--lighter-grey,5%)};
}

// dark themes

.bg--transparent {
    --theme--background-color: transparent;
    background: transparent !important;
}

.theme--green, .theme--bark, .theme--black, .theme--river, .theme--forest {
    @include on-dark-theme-vars;
}
.theme--bark {
    --theme--background-color: #{$brand--bark};
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
    --theme--rule--color: #{lighten($brand--bark, 15%)};
    --theme--sub-form--bg-color: #{lighten($brand--bark, 5%)};
}
.theme--river {
    --theme--background-color: #{$brand--river};
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
    --theme--rule--color: #{lighten($brand--river, 10%)};
    --theme--sub-form--bg-color: #{lighten($brand--river, 5%)};
}
.theme--black {
    --theme--background-color: black;
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
    --theme--rule--color: #{$brand--river};
    --theme--sub-form--bg-color: #{lighten(black, 5%)};
}
.theme--forest {
    --theme--color: #{lighten($brand--sand--light, 5%)};
    color: var(--theme--color);
    --theme--background-color: #{$brand--forest};
    --theme--color--emphasis--invert: var(--theme--background-color);
    background: var(--theme--background-color);
    --theme--color--subtle: #{lighten($brand--leaf, 15%)};
    --theme--decor--color: #{lighten($brand--leaf, 15%)};
    --theme--rule--color: #{lighten($brand--forest, 7%)};
    --theme--sub-form--bg-color: #{lighten($brand--forest, 5%)};
    --theme--btn--secondary--bg-color: #{$brand--bark};
    --theme--btn--secondary--border-color: #{$brand--bark};
}
